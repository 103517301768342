@use "../index.scss" as *;

$whatsapp-color: #47c856;

nav {
	display: flex;
	position: fixed;
	height: var(--nav-height);
	justify-content: space-between;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
	background: var(--white);
	align-items: center;
	padding: 0 15px;
	width: 100%;
	z-index: 5;
	margin: 0;
	top: 0;

	@include mobile-navigation {
		padding: 0 10px;
	}
}

.right, .left {
	display: flex;
	align-items: center;
	height: 80%;
	gap: 20px;

	@include mobile-navigation {
		gap: 5px;
	}
}

.right {
	gap: 18px;

	& > img {
		height: 70%;
	}

	.nav-item {
		user-select: none;
		font-weight: 600;
		font-size: 18px;
		cursor: pointer;
		font-family: Arial, sans-serif;
		transition: all 0.25s ease-in-out;

		&:hover {
			color: var(--theme);
		}
	}

	.company-name-mobile {
		display: none;
	}

	@include mobile-navigation {
		gap: 3px;

		& > img {
			height: 70%;
			margin-left: 5px;
		}

		.nav-item {
			display: none;
		}

		.company-name-mobile {
			display: block;
			font-size: 17px;
			font-weight: 500;

			&:hover {
				color: initial;
			}
		}
	}
}

.left {
	gap: 15px;
	padding-right: 10px;

	& > .round-button:nth-child(1) {
		border: 1px solid $dark-theme !important;
		box-shadow: 0 0 10px rgba(black, .1);
	}

	@include mobile-navigation {
		display: none;
	}
}

.round-button {
	width: 90px;
	height: 36px;
	font-size: 14px;
	font-weight: 600;
	color: var(--white);
	border-radius: 45px;
	background: var(--theme);
	transition: all 0.2s ease-in-out;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

	&:hover {
		background: darken($theme, 3%);
		color: white;
	}
}

.whatsapp {
	background: none;
	justify-content: center;
	background: $whatsapp-color;
	border: 1px solid darken($whatsapp-color, 5%);
	align-items: center;
	padding: 0 15px;
	display: flex;
	width: auto;
	gap: 10px;

	.whatsapp-mobile {
		display: none;
	}

	img {
		height: 80%;
		flex-shrink: 0;
	}

	&:hover {
		background: darken($whatsapp-color, 4%);
	}

	span {
		color: var(--white);
	}
}

.hamburger {
	display: none;
	flex-direction: column;
	justify-content: center;
	background: none;
	height: 45px;
	width: 45px;
	gap: 8.5px;

	&, * {
		transition: all 0.2s ease-in-out;
	}

	.line {
		width: 100%;
		height: 2px;
		background: var(--text-color);
	}

	@include mobile-navigation {
		display: flex;
	}
}

.hamburger-open {
	margin-left: 0;

	.line:nth-child(1) {
		transform: rotate(45deg) scale(.83) translateY(10px);
	}

	.line:nth-child(2) {
		display: none;
	}

	.line:nth-child(3) {
		transform: rotate(-45deg) scale(.83) translateY(-8px);
	}
}

.hamburger-menu {
	$gap: 15px;
	display: none;
	position: fixed;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	top: var(--nav-height);
	height: calc(100vh - var(--nav-height));
	background: rgba(230, 230, 230, 1);
	backdrop-filter: blur(3px);
	padding: 30px 25px 10px;
	width: 100vw;
	z-index: 4;
	gap: $gap;
	left: 0;

	.nav-item {
		font-size: 20px;
		font-weight: 500;
		cursor: pointer;
		transition: all 0.25s ease-in-out;
		width: 100%;

		&::after, &::before {
			height: 1px;
			content: "";
			display: block;
			margin-top: $gap;
			background: var(--text-color);
		}

		&:not(:first-child)::before {
			display: none;
		}

		&:first-child::before {
			margin-top: 0;
			margin-bottom: $gap;
		}
	}
}

.hamburger-menu-open {
	display: flex;
	animation: fadeIn .3s ease-in-out;
}

.hamburger-menu-closing {
	animation: fadeOut .3s ease-in-out;
}

.hamburger-menu-footer {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
	margin-top: auto;
	width: 100%;
	gap: 15px;

	.round-button {
		width: 100%;
		font-size: 15px;
	}

	.whatsapp {
		span {
			font-size: 15px;
		}
	}
}


@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-100%);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeOut {
	from {
		display: flex;
		transform: translateY(0);
		opacity: 1;
	}

	to {
		display: flex;
		transform: translateY(-100%);
		opacity: 0;
	}
}



.App > footer {
	display: flex;
	align-items: center;
	background: var(--white);
	color: var(--text-color);
	padding: 0 20px;
	height: 40px;
	width: 100%;
	gap: 15px;

	* {
		font-size: 13px;
	}


	div {
		height: 60%;
		width: 1px;
		background: black;
	}

	.footer-item {
		cursor: pointer;
	}

	@include mobile {
		padding: 0 12px;
		gap: 10px;

		label {
			display: none;
		}

		div:first-of-type {
			display: none;
		}

		* {
			font-size: 12px;
			margin: 0 !important;
		}
	}
}
