@use "./index.scss" as *;

$crisis-background: #f0f2f5;


.App {
	margin-top: var(--nav-height);
	height: calc(100vh - var(--nav-height));
	overflow-y: auto;
}

.section {
	$padding-size: 50px;
	$padding-mobile: 30px;
	border-top: 0.5px solid #aaa;
	min-height: calc(100vh - var(--nav-height));
	padding-bottom: $padding-size;
	flex-direction: column;
	padding-inline: 15px;
	align-items: center;
	padding-top: 0;
	display: flex;

	& > h3 {
		font-size: 20px;
		font-weight: 500;
		margin-top: 20px;
		white-space: pre-wrap;
		width: min(900px, 90%);
	}

	.section-header {
		width: 100%;
		height: $padding-size;
	}

	@include mobile {
		padding: 0 calc(#{$padding-mobile} / 2) $padding-mobile;

		.section-header {
			height: $padding-mobile;
		}

		& > h3 {
			font-size: 17px;
			margin-top: 15px;
			text-align: start;
		}
	}
}

.Header {
	min-height: calc(100vh - var(--nav-height) - 50vh) !important;
	background: var(--theme);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 1;

	& > h2, .header-date {
		margin-top: 10px;
		color: var(--white);
		width: min(800px, 90%);
		text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
	}

	& > h4 {
		font-size: 20px;
		line-height: 26px;
		font-weight: 500;
		margin-top: 20px;
		width: 600px;
	}

	.Header-extra-div {
		width: 50%;
		height: 00px;
		border: 1px dashed;
		margin-top: 30px;
		border-radius: 10px;
	}

	@include mobile {
		min-height: calc(100vh - var(--nav-height) - 55vh) !important;

		& > h1 {
			font-size: 30px;
		}

		& > h2 {
			font-size: 24px;
		}
	}
}

.About {
	background: #fcfcfc;

	.AboutBoxes {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 45px;
		max-width: 100%;
		gap: 50px 40px;

		@include mobile {
			margin-top: 30px;
			gap: 25px;
		}
	}

	.AboutBox {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		user-select: none;
		width: 40%;

		img {
			width: 47px;
			aspect-ratio: 1;
		}

		h3 {
			color: #141414;
			font-size: 20px;
			margin-top: 12px;
			font-weight: 600;
		}

		p {
			color: #373737;
			font-size: 16px;
			margin-top: 10px;
			line-height: 1.3;
			user-select: text;
			text-align: start;
			white-space: pre-wrap;
		}

		@include mobile {
			width: min(95%, 520px);

			img {
				width: 40px;
			}

			h3 {
				font-size: 18px;
				margin-top: 8px;
			}

			p {
				font-size: 15px;
				margin-top: 8px;
			}
		}
	}
}

.CrisisManagement {
	background: $crisis-background;
	min-height: calc(100vh - var(--nav-height) - 20vh) !important;
}

.CrisisBoxes {
	position: relative;
	justify-content: center;
	margin-top: 30px;
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	gap: 25px;

	@include mobile {
		margin-top: 20px;
		width: 98.5vw;
		gap: 7px;
	}
}

.Crisis {
	position: relative;
	background: $crisis-background;
	border: 1px solid $theme;
	border-radius: 10px;
	overflow: hidden;
	padding: 10px 5px;
	height: 120px;
	width: 240px;
	z-index: 3;

	h3 {
		font-size: 17px;
		font-weight: 500;
	}

	p {
		color: rgb(45, 45, 45);
		margin-top: 8px;
		font-size: 15px;
	}

	& > img {
		position: absolute;
		bottom: 5px;
		right: 5px;
		height: 15px;
	}

	@include mobile {
		width: 175px;
		height: 100px;
		padding: 7px 3px;

		h3 {
			font-size: 15px;
		}

		p {
			font-size: 13px;
			margin-top: 5px;
		}

		& > img {
			height: 14px;
			bottom: 4px;
			right: 4px;
		}
	}
}

.Struggles {
	background: $light-theme;
	min-height: calc(100vh - var(--nav-height) - 20vh) !important;

	.Crisis {
		border: 1px solid $theme;
	}
}

.ArticlesBoxes {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	justify-content: center;
	margin-bottom: 50px;
	margin-top: 30px;
	overflow: auto;
	gap: 15px;

	@include mobile {
		gap: 15px;
	}
}

.Article {
	display: flex;
	border: 0.3px solid rgb(100, 100, 100);
	border-bottom: 4px solid $mid-theme;
	flex-direction: column;
	width: min(90%, 325px);
	align-items: center;
	border-radius: 5px;
	$upper-height: 43%;
	aspect-ratio: 1.05;
	cursor: pointer;

	.upper {
		@extend .flex-center;
		background: $mid-theme;
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
		height: $upper-height;
		padding: 10px;
		width: 100%;

		h3 {
			font-size: 20px;
			color: var(--white);
			text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
		}
	}

	.lower {
		@extend .flex-column;
		justify-content: space-between;
		height: calc(100% - #{$upper-height});
		width: 100%;

		p {
			font-size: 14.5px;
			text-align: start;
			color: rgb(45, 45, 45);
			scrollbar-width: thin;
			line-height: 1.2;
			overflow: auto;
			padding: 4px 3px;
		}

		& > div {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-inline: 5px;
			margin-bottom: 6px;
			flex-shrink: 0;

			& > img {
				max-height: 45px !important;
				max-width: 75px;
				height: auto;
				border-radius: 3px;
			}

			& > span {
				font-size: 14px;
				color: #555;
			}
		}
	}
}

.Contact {
	background: $theme;
	min-height: calc(100vh - var(--nav-height) - 40px) !important;

	h2, h4 {
		color: var(--white);
	}
}

.ContactContent {
	@extend .flex-column;
	background: $crisis-background;
	border-radius: 10px;
	margin-top: 20vh;
	padding: 18px;
	gap: 14px;

	&, * {
		direction: ltr !important;
	}

	& > div {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;

		img {
			width: 35px;
			height: auto;
		}

		span {
			font-size: 19px;
			font-weight: 500;
		}

		&:not(:first-child) {
			cursor: pointer;
		}
	}

	.whatsapp-contact {
		direction: rtl;
		margin-top: 5px;
	}

	.call-us {
		direction: rtl;
		background: $theme;
		display: none;

		&:hover {
			background: darken($theme, 2%);
		}

		@include mobile {
			display: flex;
			//color: rgb($theme, 0.5);
		}
	}
}


@include mobile {
	.Article {
		border-bottom: 3px solid $mid-theme;
		width: min(100%, 350px);
		aspect-ratio: initial;

		.upper {
			height: 130px;
		}

		.lower {
			justify-content: space-between;
			height: initial;

			p {
				font-size: 14px;
				overflow: hidden;
			}

			& > img {
				width: 50px;
				margin-top: 5px;
				margin-bottom: 4px;
			}
		}
	}
}
