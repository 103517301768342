@use "../index.scss" as *;


.ArticleModalWrapper {
	background: rgba(black, 0.7);
	backdrop-filter: blur(3px);
	justify-content: center;
	align-items: center;
	position: fixed;
	display: flex;
	z-index: 100;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.ArticleModal {
	position: relative;
	box-shadow: 0 0 10px rgba(black, 0.5);
	background-color: white;
	border-radius: 10px;
	overflow-y: auto;
	min-height: 50px;
	max-height: 93%;
	padding: 20px;
	width: 96%;
}

.ArticleModal header {
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	display: flex;
	gap: 10px;
	
	h1 {
		font-size: 22px;
		margin: 0;
	}
	
	p {
		font-size: 18px;
	}
}

.ArticleModal main {
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	display: flex;
	gap: 10px;
	
	p, h2 {
		width: calc(100% - 70px);
		white-space: pre-line;
		text-align: start;
		font-size: 16px;
		margin: 0;
	}
	
	h2 {
		font-weight: 600;
		margin: 0;
	}
	
	.img {
		height: 250px;
		margin-block: 20px;
		border-radius: 5px;
	}
	
	.img-max {
		width: 100%;
		height: auto;
	}
}

.ArticleModal .close {
	font-size: 30px;
	justify-content: center;
	align-items: center;
	position: absolute;
	cursor: pointer;
	display: flex;
	left: 20px;
	top: 10px;
}

.ArticleModal button {
	background: $dark-theme;
	border-radius: 10px;
	padding: 10px 20px;
	position: absolute;
	cursor: pointer;
	font-size: 14px;
	color: white;
	border: none;
	right: 20px;
	top: 25px;
}


.ArticleModal > img {
	width: 80%;
	box-sizing: border-box;
}


@include mobile {
	.ArticleModal {
		width: calc(100% - 25px);
		max-height: calc(100% - 40px);

		button {
			top: 10px;
		}
		
		.close {
			top: 5px;
		}
		
		header {
			margin-top: 60px;
			
			h1 {
				font-size: 18px;
				text-align: start;
			}
			
			p {
				font-size: 16px;
				text-align: start;
				width: 100%;
			}
		}
		
		main {
			margin-block: 20px;
			
			p, h2 {
				font-size: 15px;
				width: 100%;
				margin: 0;
			}
			
			h2 {
				font-size: 17px;
			}
			
			img {
				height: 175px;
			}
		}
	}
}
