@use "../index.scss" as *;


.CollectionPage {
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background: #eee;
	padding-bottom: 100px;
}

.logos {
	$pos: 15px;
	display: flex;
	justify-content: space-between;
	padding-inline: $pos;
	margin-top: $pos;
	width: 100vw;

	img {
		height: 70px;
		cursor: pointer;
		transition: 0.3s ease-in-out;
	}

	.client-logo[title="האיגוד הישראלי לרפואת ריאות"] {
		padding: 4px;
		background: white;
		border-radius: 50%;
		height: 85px;
	}

	.client-logo[title="עמותת רופאי חלום"] {
		background: white;
		border-radius: 8px;
		padding: 3px;
		height: 78px;
	}

	.client-logo[title="עמותת צעדים קטנים"] {
		background: white;
		border-radius: 8px;
		padding: 3px;
		height: 65px;
	}

	.client-logo[title="האיגוד הישראלי ליבואני התבואות"] {
		border-radius: 5px;
		background: white;
		cursor: auto;
		height: 78px;
	}

	.client-logo[title="האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית"] {
		background-color: white;
		padding-inline: 3px;
		border-radius: 5px;
		height: 85px;
	}

	.client-logo[title="האיגוד הנוירולוגי בישראל"] {
		height: 85px;
	}

	@include ipad {
		$pos: 10px;

		padding-inline: $pos;
		margin-top: $pos;

		img {
			height: 65px;
		}

		.client-logo[title="האיגוד הישראלי לרפואת ריאות"] {
			height: 85px;
		}

		.client-logo[title="עמותת רופאי חלום"], .client-logo[title="האיגוד הישראלי ליבואני התבואות"], .client-logo[title="האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית"] {
			height: 73px;
		}

		.client-logo[title="עמותת צעדים קטנים"] {
			height: 58px;
		}

		.client-logo[title="האיגוד הנוירולוגי בישראל"] {
			height: 75px;
		}
	}

	@include mobile {
		$pos: 7px;

		padding-inline: $pos;
		margin-top: $pos;

		img {
			height: 57px;
		}

		.client-logo[title="האיגוד הישראלי לרפואת ריאות"] {
			height: 70px;
		}

		.client-logo[title="עמותת רופאי חלום"], .client-logo[title="האיגוד הישראלי ליבואני התבואות"], .client-logo[title="האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית"] {
			height: 65px;
		}

		.client-logo[title="עמותת צעדים קטנים"] {
			height: 55px;
		}

		.client-logo[title="האיגוד הנוירולוגי בישראל"] {
			height: 70px;
		}
	}
}

.collection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.collection > header {
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: column;
	min-height: 35vh !important;
	background: var(--theme);
	width: 100%;

	h1 {
		font-size: 36px;
		margin-top: 20px;
	}

	span {
		font-size: 24px;
		color: var(--white);
		margin-top: 8px;
		text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
	}

	@include mobile {
		min-height: 250px !important;

		h1 {
			font-size: 30px !important;
			margin-top: 30px;
		}

		span {
			font-size: 22px !important;
		}
	}
}

.collection > main {
	display: flex;
	flex-direction: column;

	& > span {
		margin-block: 35px;
		font-size: 20px;
	}

	.articles {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 60px;
		margin-inline: 10px;
		gap: 25px;
	}
}

.collection > footer {
	display: flex;
	margin-top: auto;
	background: var(--theme);
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 100%;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		gap: 10px;

		img {
			height: 35px;
		}

		span {
			font-size: 16px;
			color: var(--white);
			text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
			font-weight: 500;
		}
	}

	div:last-of-type {
		flex-direction: row-reverse;

		img {
			height: 40px;
		}

		&[title="האיגוד הישראלי ליבואני התבואות"], &[title="האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית"] {
			border-radius: 5px;
			cursor: auto;
		}

		img[title="האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית"] {
			padding-inline: 3px;
			border-radius: 5px;
			background: white;
		}
	}

	@include mobile {
		padding: 10px;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;

		div {
			gap: 8px;

			img {
				height: 30px !important;
			}

			span {
				font-size: 14px;
			}
		}

		div:last-of-type {
			flex-direction: row;

			img {
				height: 35px;
			}
		}
	}
}

.article {
	width: 310px;
	flex-direction: column;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
	border: 0.3px solid rgb(100, 100, 100);
	border-bottom: 4px solid $mid-theme;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;

	&, * {
		transition: 0.2s ease-in-out all;
	}

	.article-upper {
		@extend .flex-center;
		position: relative;
		background: $mid-theme;
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
		overflow: hidden;
		height: 125px;
		padding: 10px;
		width: 100%;

		h3 {
			font-size: 18px;
			color: var(--white);
			text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
		}

		span {
			position: absolute;
			$pos: 5px;
			top: $pos;
			right: $pos;
			font-size: 12px;
			user-select: none;
			color: rgb(60, 60, 60);
		}
	}

	.article-lower {
		@extend .flex-column;

		justify-content: flex-start;
		align-items: center;
		position: relative;

		.controls {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding-inline: 8px;
			margin-top: 5px;

			span {
				color: #444;
				font-size: 16px;
				text-align: start;
				direction: rtl !important;
			}
		}

		& > img {
			height: 150px;
			max-width: 100%;
			border-radius: 3px;
			margin-bottom: 5px;
			margin-top: 10px;
		}

		* {
			direction: ltr !important;
		}
	}

	.article-lower-audio {
		justify-content: space-between;

		& > div {
			display: flex;
			justify-content: space-between;
			width: calc(100% - 16px);
			align-items: flex-start;
			margin-top: 5px;


			span {
				color: #444;
				font-size: 16px;
				text-align: start;
			}

			& > img {
				height: 130px;
				border-radius: 5px;
			}
		}

		.controls {
			width: auto;
			display: flex;
			align-items: flex-end;
			flex-direction: column;
			margin-top: 0;
			padding: 0;

			span {
				color: #444;
				font-size: 16px;
				text-align: start;
			}
		}

		.audio-player {
			width: 95%;
			height: 45px;
			margin-top: 10px;
			margin-bottom: 5px;
		}
	}

	.article-lower-video {
		& > div:not(.controls) {
			$width: 267px;

			margin-top: 10px;
			margin-bottom: 5px;
			width: $width !important;
			height: calc(#{$width} * 9 / 16) !important;
			padding: 0 !important;
		}

		.video-player, video {
			border-radius: 3px;
		}
	}

	&:hover {
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	}

	@media (max-width: 660px) {
		width: 400px;

		.article-lower img {
				height: 160px;
		}
	}

	@media (max-width: 475px) {
		width: 95%;

		.article-upper {
			height: 115px;
			padding: 8px;

			h3 {
				font-size: 17px;
			}

			span {
				color: rgb(68, 68, 68);
			}
		}

		.article-lower img {
				height: 140px;
		}
	}
}

.collections {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 20px;
	padding-block: 20px;
	margin-inline: 30px;
	gap: 20px;

	& > div {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		border-radius: 10px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		transition: 0.3s ease-in-out;
		background-color: #fff;
		cursor: pointer;
		width: 360px;

		&:hover {
			//transform: scale(1.03);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		}


		& > div:first-of-type {
			display: flex;
			justify-content: center;
			background: var(--theme);
			align-items: center;
			padding: 5px;
			height: 75px;
			width: 100%;

			& > span {
				font-size: 20px;
				font-weight: 500;
				color: var(--white);
				text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
			}
		}

		& > div:last-of-type {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			padding: 10px;
			width: 100%;
			gap: 10px;

			span {
				font-size: 16px;
				margin-top: 10px;
			}

			button {
				padding: 6px 15px;
				border: none;
				border-radius: 5px;
				background-color: dodgerblue;
				font-size: 14px;
				cursor: pointer;
				color: #fff;
			}
		}
	}

	.logo-index {
		position: absolute;
		background-color: white;
		border-radius: 5px;
		width: 60px;
		padding: 3px;
		bottom: 3px;
		right: 5px;
	}
}

.go-up {
	$pos: 10px;
	top: $pos;
	right: $pos;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	transition: 0.2s ease-in-out;
	border: 1px solid white;
	border-radius: 50%;
	background: white;
	cursor: pointer;
	opacity: 0;

	img {
		height: 48px;
	}
}

.go-up-visible {
	opacity: 1;
}

.go-up-hidden {
	opacity: 0;
}



@include mobile {
	.hebrew {
		flex-direction: column-reverse !important;
	}

	.items {
		margin-bottom: 60px;
	}
}
