
:root {
  --theme: rgb(40, 160, 210);
  --text-color: rgb(15, 15, 15);
  --white: rgb(245, 245, 245);
  --nav-height: 65px;
}

@mixin ipad {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin mobile-navigation {
  @media (max-width: 750px) {
    @content;
  }
}

$theme: rgb(40, 160, 210);
$mid-theme: #28b4e6;
$light-theme: #87d7fa;
$dark-theme: #1e96c8;

@include mobile {
  :root {
    --nav-height: 50px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  direction: rtl !important;
  box-sizing: border-box;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input, textarea, button {
  border: none;
  outline: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo {
  @extend .flex-center;
  height: 100vh;
  width: 100vw;
  background-color: #111;

  & > div {
    background: #eee;
    width: 800px;
    height: 500px;
  }
}

span, p, h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  margin: 0;
}

span {
  font-size: 14px;
}

b {
  font-weight: 500;
  font-size: 14px;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

img {
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@include mobile {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 17px;
  }
}