@use "../index.scss" as *;


.collection-report header {
	$pos: 10px;

	& > h1 {
		font-size: 26px;
		font-weight: 600;
		margin-top: 30px;
		margin-bottom: 5px;
	}


	& > h2 {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 25px;
	}

	& > img {
		position: absolute;
		top: $pos;
		left: $pos;

		height: 95px;
	}

	& > img:last-of-type {
		left: unset;
		right: $pos;
		height: 70px;
	}
}


.collection-report main {
	display: flex;
	justify-content: center;
	padding-top: 5px;
	flex-wrap: wrap;
	width: 100%;
	gap: 8px;

	.article {
		width: 200px;
		border-bottom: 2px solid $mid-theme;

		.article-upper {
			height: 85px;
			padding: 5px;
			width: 100%;

			h3 {
				font-size: 14px;
				text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
				font-weight: 600;
			}

			span {
				display: none;
			}
		}

		.article-lower {
			.controls {
				padding-inline: 3px;
				margin-top: 3px;

				span {
					color: #444;
					font-size: 12px;
				}
			}

			& > img {
				height: 100px;
				margin-top: 5px;
			}
		}
	}
}

